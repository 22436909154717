import classNames from 'classnames';
import { getThemeClasses, baseStyles } from '../../ui.style.tailwind';

const mode = 'dark';
const themeClasses = getThemeClasses(mode);

export const leftColumnsClasses = 'col-start-1 col-end-3';
export const rightTwoFirstRowsColumnsClasses = 'col-start-3 col-end-5';

export const mainDashboardClasses = classNames(
  //* All screen sizes
  'space-y-5 p-4 overflow-auto scroll-smooth h-full snap-y gap-4 scrollbar-thin',
  //* Laptop and above (Min-width 1024px)
  'laptop:space-y-0 laptop:grid laptop:grid-cols-4 laptop:grid-rows-10 laptop:scrollbar-none',
);

const gridLayouts = {
  leftTop:
    'laptop:row-start-1 laptop:row-end-7 laptop:col-start-1 laptop:col-end-3',
  leftBottom:
    'laptop:row-start-7 laptop:row-end-11 laptop:col-start-1 laptop:col-end-3',
  rightTop: 'laptop:row-end-2 laptop:col-start-3 laptop:col-end-5',
  rightMiddle:
    'laptop:row-start-2 laptop:row-end-6 laptop:col-start-3 laptop:col-end-5',
  rightBottomLeft:
    'laptop:row-start-6 laptop:row-end-11 laptop:col-start-3 laptop:col-end-4',
  rightBottomRight:
    'laptop:row-start-6 laptop:row-end-11 laptop:col-start-4 laptop:col-end-5',
};

export const leftTopClasses = classNames(
  //* All screen sizes
  'overflow-y-hidden',
  'overflow-x-hidden',
  'snap',
  'h-fit',
  'laptop:m-0',
  'bg-gray-100',
  'hover:ring-blue-500',
  leftColumnsClasses,
  'bg-gray-100',
  ' ring',
  ' ring-gray-300',
  baseStyles.borderRadius.base.rounded.default,

  'p-4',
  //* Min-width 1024px - Laptop
  gridLayouts.leftTop,
);

export const leftBottomClasses = classNames(
  //* All screen sizes
  'overflow-y-hidden',
  'overflow-x-hidden',
  'snap',
  'laptop:m-0',
  'bg-gray-100',
  'hover:ring-blue-500',
  leftColumnsClasses,
  'bg-gray-100',
  ' ring',
  ' ring-gray-300',
  baseStyles.borderRadius.base.rounded.default,

  'p-4',
  //* Min-width 1024px - Laptop
  gridLayouts.leftBottom,
);

export const rightTopClasses = classNames(
  //* All screen sizes
  'overflow-y-hidden',
  'overflow-x-hidden',
  'snap',
  'laptop:m-0',
  themeClasses.backgroundColor,
  'ring',
  'ring-secondary_dark',
  rightTwoFirstRowsColumnsClasses,
  baseStyles.borderRadius.base.rounded.default,

  'p-4',
  //* Min-width 1024px - Laptop
  gridLayouts.rightTop,
);

export const rightMiddleClasses = classNames(
  //* All screen sizes
  'overflow-y-hidden',
  'overflow-x-hidden',
  'snap',
  'h-full',
  'max-h-full',
  'laptop:m-0',
  'bg-gray-100',
  'hover:ring-blue-500',
  rightTwoFirstRowsColumnsClasses,
  'bg-gray-100',
  ' ring',
  ' ring-gray-300',
  baseStyles.borderRadius.base.rounded.default,

  'p-4',
  //* Min-width 1024px - Laptop
  gridLayouts.rightMiddle,
);

export const rightBottomLeftClasses = classNames(
  //* All screen sizes
  'overflow-y-hidden',
  'overflow-x-hidden',
  'snap',
  'h-full',
  'max-h-full',
  'laptop:m-0',
  'bg-gray-100',
  'hover:ring-blue-500',
  'bg-gray-100',
  ' ring',
  ' ring-gray-300',
  baseStyles.borderRadius.base.rounded.default,

  'p-4',
  //* Min-width 1024px - Laptop
  gridLayouts.rightBottomLeft,
);

export const rightBottomRightClasses = classNames(
  //* All screen sizes
  'overflow-y-hidden',
  'overflow-x-hidden',
  'snap',
  'h-full',
  'max-h-full',
  'laptop:m-0',
  'bg-gray-100',
  'hover:ring-blue-500',
  'bg-gray-100',
  ' ring',
  ' ring-gray-300',
  baseStyles.borderRadius.base.rounded.default,

  'p-4',
  //* Min-width 1024px - Laptop
  gridLayouts.rightBottomRight,
);
