import classNames from 'classnames';
import { getThemeClasses, baseStyles } from '../ui.style.tailwind';

// Set the mode (can be dynamic)
const mode = 'dark';
const themeClasses = getThemeClasses(mode);

// lib/routes/dashboard/amenities/src/lib/frontend//*route-amenities.tsx
export const routeClasses = classNames(
  'w-full',
  'max-h-fit',
  'bg-gray-200',
  'p-4',
  'overflow-hidden',
  baseStyles.borderRadius.base.rounded.default,

);
