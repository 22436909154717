// Define types for the color modes and styles

//! primary_dark: '#132F51',
//! payment_background: '#214882',
//! primary_light: '#1AB0FF',
//! primary_light_2: '#0D7FC0',
//! secondary_dark: '#1D62CB',
//! secondary_light: '#19A9F8',
//! third_color: '#0D7FC0',

type ColorMode = 'light' | 'dark';

type HoverStyles = {
  backgroundColor: string;
  textColor: string;
  oppositeBackgroundColor: string;
  oppositeTextColor: string;
}

type ActiveStyles = {
  backgroundColor: string;
  textColor: string;
}

type FocusStyles = {
  ringColor: string;
}

type AdditionalStyles = {
  textColor?: string;
  borderColor?: string;
}

type ThemeColors = {
  color: string;
  backgroundColor: string;
  textColor: string;
  oppositeBackgroundColor: string; // Opposite background color for non-hover state
  oppositeTextColor: string; // Opposite text color for non-hover state
  fillSVG: string; // New property for SVG fill color
  hover: HoverStyles;
  active: ActiveStyles;
  focus: FocusStyles;
  additionalStyles?: AdditionalStyles;
}

// Define the color schemes for light and dark modes
export const tailwindColors: Record<ColorMode, ThemeColors> = {
  light: {
    color: 'bg-white',
    backgroundColor: 'bg-white',
    textColor: 'text-black',
    oppositeBackgroundColor: 'bg-black', // Opposite background color for non-hover state
    oppositeTextColor: 'text-white', // Opposite text color for non-hover state
    fillSVG: 'fill-primary_light', // Fill color for light mode
    hover: {
      backgroundColor: 'hover:bg-gray-100',
      textColor: 'hover:text-gray-700',
      oppositeBackgroundColor: 'hover:bg-black',
      oppositeTextColor: 'hover:text-white',
    },
    active: {
      backgroundColor: 'active:bg-gray-200',
      textColor: 'active:text-black',
    },
    focus: {
      ringColor: 'focus:ring-gray-500',
    },
  },
  dark: {
    color: 'secondary_dark',
    // backgroundColor: 'bg-secondary_dark',
    backgroundColor: 'bg-secondary_dark',
    textColor: 'text-white',
    oppositeBackgroundColor: 'bg-white',
    oppositeTextColor: 'text-secondary_dark',
    fillSVG: 'fill-secondary_dark', // Fill color for dark mode
    hover: {
      backgroundColor: 'hover:bg-secondary_dark',
      textColor: 'hover:text-white',
      oppositeBackgroundColor: 'hover:bg-white',
      oppositeTextColor: 'hover:text-secondary_dark',
    },
    active: {
      backgroundColor: 'active:bg-secondary_dark',
      textColor: 'active:text-white',
    },
    focus: {
      ringColor: 'focus:ring-secondary_dark',
    },
    additionalStyles: {
      textColor: 'text-secondary_dark',
      borderColor: 'border-secondary_dark',
    },
  },
};

/**
 * Utility function to get the theme classes based on the provided mode.
 *
 * @param mode - The color mode ('light' or 'dark'). Defaults to 'light'.
 * @returns An object containing the Tailwind CSS classes for background, text, hover, active, focus, and additional styles.
 */
export function getThemeClasses(mode: ColorMode = 'light') {
  const theme = tailwindColors[mode];

  return {
    color: theme.color,
    backgroundColor: theme.backgroundColor,
    textColor: theme.textColor,
    fillSVG: theme.fillSVG,
    oppositeBackgroundColor: theme.oppositeBackgroundColor, // Opposite background color for non-hover state
    oppositeTextColor: theme.oppositeTextColor, // Opposite text color for non-hover state
    hoverBackgroundColor: theme.hover.backgroundColor,
    hoverTextColor: theme.hover.textColor,
    hoverOppositeBackgroundColor: theme.hover.oppositeBackgroundColor,
    hoverOppositeTextColor: theme.hover.oppositeTextColor,
    activeBackgroundColor: theme.active.backgroundColor,
    activeTextColor: theme.active.textColor,
    focusRingColor: theme.focus.ringColor,
    additionalStyles: theme.additionalStyles || {},
    borderRadius: baseStyles.borderRadius.base.rounded.default,
    // Include base border radius
  };
}


/**
 * Base styles for common UI elements across all themes.
 *
 * @typedef {Object} BaseStyles
 * @property {string} borderRadius - The default border radius applied to elements, using Tailwind CSS classes. Value: `'rounded-xl'`.
 * @property {Object} borderRadiusOptions - Additional border radius options using Tailwind CSS classes.
 * @property {string} borderRadiusOptions.none - No border radius. Value: `'rounded-none'`.
 * @property {string} borderRadiusOptions.small - Small border radius. Value: `'rounded-sm'`.
 * @property {string} borderRadiusOptions.base - Base border radius. Value: `'rounded'`.
 * @property {string} borderRadiusOptions.medium - Medium border radius. Value: `'rounded-md'`.
 * @property {string} borderRadiusOptions.large - Large border radius. Value: `'rounded-lg'`.
 * @property {string} borderRadiusOptions.extraLarge - Extra large border radius. Value: `'rounded-xl'`.
 * @property {string} borderRadiusOptions.full - Full border radius for circular elements. Value: `'rounded-full'`.
 * @property {Object} textSize - Defines text sizes for different devices.
 * @property {string} textSize.small - Small text size. Value: `'text-sm'`.
 * @property {string} textSize.base - Default text size. Value: `'text-base'`.
 * @property {string} textSize.large - Large text size. Value: `'text-lg'`.
 * @property {Object} textSize.laptop - Text sizes specifically for laptop devices.
 * @property {string} textSize.laptop.base - Base text size for laptops. Value: `'laptop:text-base'`.
 * @property {string} textSize.laptop.h3 - Heading 3 text size for laptops. Value: `'laptop:text-lg'`.
 * @property {string} textSize.laptop.h2 - Heading 2 text size for laptops. Value: `'laptop:text-xl'`.
 * @property {string} textSize.laptop.h1 - Heading 1 text size for laptops. Value: `'laptop:text-2xl'`.
 * @property {Object} textSize.phone - Text sizes specifically for phone devices.
 * @property {string} textSize.phone.base - Base text size for phones. Value: `'phone:text-sm'`.
 * @property {string} textSize.phone.h3 - Heading 3 text size for phones. Value: `'phone:text-base'`.
 * @property {string} textSize.phone.h2 - Heading 2 text size for phones. Value: `'phone:text-lg'`.
 * @property {string} textSize.phone.h1 - Heading 1 text size for phones. Value: `'phone:text-xl'`.
 * @property {Object} fontWeight - Defines font weights for different text elements.
 * @property {string} fontWeight.base - Default font weight for general text. Value: `'font-normal'`.
 * @property {string} fontWeight.heading - Font weight for headings, typically bold. Value: `'font-bold'`.
 * @property {string} fontWeight.emphasis - Font weight for emphasized text, typically semi-bold. Value: `'font-semibold'`.
 */

/** @type {BaseStyles} */
export const baseStyles = {
  flex: 'flex',
  flexWrap: 'flex-wrap',
  padding: {
    small: 'p-2',
    base: 'p-4',
    large: 'p-6',
  },
  color: {
    primary: '#132F51',
    payment_background: '#214882',
    primary_light: '#1AB0FF',
    primary_light_2: '#0D7FC0',
    secondary_dark: '#1D62CB',
    secondary_light: '#19A9F8',
    third_color: '#0D7FC0',
  },
  border: {
    default: 'border-gray-300',
    widths: {
      '0': 'border-0', // border-width: 0px;
      '2': 'border-2', // border-width: 2px;
      '4': 'border-4', // border-width: 4px;
      '8': 'border-8', // border-width: 8px;
      defaultWidth: 'border', // border-width: 1px;
    },
    directions: {
      x: {
        '0': 'border-x-0', // border-left-width: 0px; border-right-width: 0px;
        '2': 'border-x-2', // border-left-width: 2px; border-right-width: 2px;
        '4': 'border-x-4', // border-left-width: 4px; border-right-width: 4px;
        '8': 'border-x-8', // border-left-width: 8px; border-right-width: 8px;
        defaultWidth: 'border-x', // border-left-width: 1px; border-right-width: 1px;
      },
      y: {
        '0': 'border-y-0', // border-top-width: 0px; border-bottom-width: 0px;
        '2': 'border-y-2', // border-top-width: 2px; border-bottom-width: 2px;
        '4': 'border-y-4', // border-top-width: 4px; border-bottom-width: 4px;
        '8': 'border-y-8', // border-top-width: 8px; border-bottom-width: 8px;
        defaultWidth: 'border-y', // border-top-width: 1px; border-bottom-width: 1px;
      },
      start: {
        '0': 'border-s-0', // border-inline-start-width: 0px;
        '2': 'border-s-2', // border-inline-start-width: 2px;
        '4': 'border-s-4', // border-inline-start-width: 4px;
        '8': 'border-s-8', // border-inline-start-width: 8px;
        defaultWidth: 'border-s', // border-inline-start-width: 1px;
      },
      end: {
        '0': 'border-e-0', // border-inline-end-width: 0px;
        '2': 'border-e-2', // border-inline-end-width: 2px;
        '4': 'border-e-4', // border-inline-end-width: 4px;
        '8': 'border-e-8', // border-inline-end-width: 8px;
        defaultWidth: 'border-e', // border-inline-end-width: 1px;
      },
      top: {
        '0': 'border-t-0', // border-top-width: 0px;
        '2': 'border-t-2', // border-top-width: 2px;
        '4': 'border-t-4', // border-top-width: 4px;
        '8': 'border-t-8', // border-top-width: 8px;
        defaultWidth: 'border-t', // border-top-width: 1px;
      },
      right: {
        '0': 'border-r-0', // border-right-width: 0px;
        '2': 'border-r-2', // border-right-width: 2px;
        '4': 'border-r-4', // border-right-width: 4px;
        '8': 'border-r-8', // border-right-width: 8px;
        defaultWidth: 'border-r', // border-right-width: 1px;
      },
      bottom: {
        '0': 'border-b-0', // border-bottom-width: 0px;
        '2': 'border-b-2', // border-bottom-width: 2px;
        '4': 'border-b-4', // border-bottom-width: 4px;
        '8': 'border-b-8', // border-bottom-width: 8px;
        defaultWidth: 'border-b', // border-bottom-width: 1px;
      },
      left: {
        '0': 'border-l-0', // border-left-width: 0px;
        '2': 'border-l-2', // border-left-width: 2px;
        '4': 'border-l-4', // border-left-width: 4px;
        '8': 'border-l-8', // border-left-width: 8px;
        defaultWidth: 'border-l', // border-left-width: 1px;
      },
    },
  },
  borderRadius: {
    base: {
      rounded: {
        default: 'rounded-xl',
        screens: {
          mobile: {
            min: 'min-[325px]:rounded-xl',
            max: 'max-[325px]:rounded-xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-xl',
            min: 'min-[768px]:rounded-xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-xl',
            min: 'min-[1024px]:rounded-xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-xl',
            min: 'min-[1280px]:rounded-xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-xl',
            min: 'min-[1920px]:rounded-xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-xl',
            min: 'min-[2560px]:rounded-xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-xl',
            min: 'min-[3072px]:rounded-xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-xl',
            min: 'min-[5120px]:rounded-xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-xl',
            min: 'min-[7680px]:rounded-xl',
          },
        },
      },
      top: {
        default: 'rounded-t-xl',
        screens: {
          mobile: {
            min: 'min-[325px]:rounded-t-xl',
            max: 'max-[325px]:rounded-t-xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-t-xl',
            min: 'min-[768px]:rounded-t-xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-t-xl',
            min: 'min-[1024px]:rounded-t-xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-t-xl',
            min: 'min-[1280px]:rounded-t-xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-t-xl',
            min: 'min-[1920px]:rounded-t-xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-t-xl',
            min: 'min-[2560px]:rounded-t-xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-t-xl',
            min: 'min-[3072px]:rounded-t-xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-t-xl',
            min: 'min-[5120px]:rounded-t-xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-t-xl',
            min: 'min-[7680px]:rounded-t-xl',
          },
        },
      },
      bottom: {
        default: 'rounded-b-xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-b-xl',
            min: 'min-[325px]:rounded-b-xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-b-xl',
            min: 'min-[768px]:rounded-b-xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-b-xl',
            min: 'min-[1024px]:rounded-b-xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-b-xl',
            min: 'min-[1280px]:rounded-b-xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-b-xl',
            min: 'min-[1920px]:rounded-b-xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-b-xl',
            min: 'min-[2560px]:rounded-b-xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-b-xl',
            min: 'min-[3072px]:rounded-b-xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-b-xl',
            min: 'min-[5120px]:rounded-b-xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-b-xl',
            min: 'min-[7680px]:rounded-b-xl',
          },
        },
      },
      left: {
        default: 'rounded-l-xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-l-xl',
            min: 'min-[325px]:rounded-l-xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-l-xl',
            min: 'min-[768px]:rounded-l-xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-l-xl',
            min: 'min-[1024px]:rounded-l-xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-l-xl',
            min: 'min-[1280px]:rounded-l-xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-l-xl',
            min: 'min-[1920px]:rounded-l-xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-l-xl',
            min: 'min-[2560px]:rounded-l-xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-l-xl',
            min: 'min-[3072px]:rounded-l-xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-l-xl',
            min: 'min-[5120px]:rounded-l-xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-l-xl',
            min: 'min-[7680px]:rounded-l-xl',
          },
        },
      },
      right: {
        default: 'rounded-r-xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-r-xl',
            min: 'min-[325px]:rounded-r-xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-r-xl',
            min: 'min-[768px]:rounded-r-xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-r-xl',
            min: 'min-[1024px]:rounded-r-xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-r-xl',
            min: 'min-[1280px]:rounded-r-xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-r-xl',
            min: 'min-[1920px]:rounded-r-xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-r-xl',
            min: 'min-[2560px]:rounded-r-xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-r-xl',
            min: 'min-[3072px]:rounded-r-xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-r-xl',
            min: 'min-[5120px]:rounded-r-xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-r-xl',
            min: 'min-[7680px]:rounded-r-xl',
          },
        },
      },
    },
    small: {
      rounded: {
        default: 'rounded-sm',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-sm',
            min: 'min-[325px]:rounded-sm',
          },
          tablet: {
            max: 'max-[768px]:rounded-sm',
            min: 'min-[768px]:rounded-sm',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-sm',
            min: 'min-[1920px]:rounded-sm',
          },
          '2k': {
            max: 'max-[2560px]:rounded-sm',
            min: 'min-[2560px]:rounded-sm',
          },
          '3k': {
            max: 'max-[3072px]:rounded-sm',
            min: 'min-[3072px]:rounded-sm',
          },
          '5k': {
            max: 'max-[5120px]:rounded-sm',
            min: 'min-[5120px]:rounded-sm',
          },
          '8k': {
            max: 'max-[7680px]:rounded-sm',
            min: 'min-[7680px]:rounded-sm',
          },
        },
      },
      top: {
        default: 'rounded-t-sm',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-t-sm',
            min: 'min-[325px]:rounded-t-sm',
          },
          tablet: {
            max: 'max-[768px]:rounded-t-sm',
            min: 'min-[768px]:rounded-t-sm',
          },
        },
      },
      bottom: {
        default: 'rounded-b-sm',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-b-sm',
            min: 'min-[325px]:rounded-b-sm',
          },
          tablet: {
            max: 'max-[768px]:rounded-b-sm',
            min: 'min-[768px]:rounded-b-sm',
          },
          laptop: {
            max: 'max-[1024px]:rounded-b-sm',
            min: 'min-[1024px]:rounded-b-sm',
          },
          desktop: {
            max: 'max-[1280px]:rounded-b-sm',
            min: 'min-[1280px]:rounded-b-sm',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-b-sm',
            min: 'min-[1920px]:rounded-b-sm',
          },
          '2k': {
            max: 'max-[2560px]:rounded-b-sm',
            min: 'min-[2560px]:rounded-b-sm',
          },
          '3k': {
            max: 'max-[3072px]:rounded-b-sm',
            min: 'min-[3072px]:rounded-b-sm',
          },
          '5k': {
            max: 'max-[5120px]:rounded-b-sm',
            min: 'min-[5120px]:rounded-b-sm',
          },
          '8k': {
            max: 'max-[7680px]:rounded-b-sm',
            min: 'min-[7680px]:rounded-b-sm',
          },
        },
      },
      left: {
        default: 'rounded-l-sm',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-l-sm',
            min: 'min-[325px]:rounded-l-sm',
          },
          tablet: {
            max: 'max-[768px]:rounded-l-sm',
            min: 'min-[768px]:rounded-l-sm',
          },
          laptop: {
            max: 'max-[1024px]:rounded-l-sm',
            min: 'min-[1024px]:rounded-l-sm',
          },
          desktop: {
            max: 'max-[1280px]:rounded-l-sm',
            min: 'min-[1280px]:rounded-l-sm',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-l-sm',
            min: 'min-[1920px]:rounded-l-sm',
          },
          '2k': {
            max: 'max-[2560px]:rounded-l-sm',
            min: 'min-[2560px]:rounded-l-sm',
          },
          '3k': {
            max: 'max-[3072px]:rounded-l-sm',
            min: 'min-[3072px]:rounded-l-sm',
          },
          '5k': {
            max: 'max-[5120px]:rounded-l-sm',
            min: 'min-[5120px]:rounded-l-sm',
          },
          '8k': {
            max: 'max-[7680px]:rounded-l-sm',
            min: 'min-[7680px]:rounded-l-sm',
          },
        },
      },
    },
    right: {
      default: 'rounded-r-sm',
      screens: {
        mobile: {
          max: 'max-[640px]:rounded-r-sm',
          min: 'min-[325px]:rounded-r-sm',
        },
        tablet: {
          max: 'max-[768px]:rounded-r-sm',
          min: 'min-[768px]:rounded-r-sm',
        },
        laptop: {
          max: 'max-[1024px]:rounded-r-sm',
          min: 'min-[1024px]:rounded-r-sm',
        },
        desktop: {
          max: 'max-[1280px]:rounded-r-sm',
          min: 'min-[1280px]:rounded-r-sm',
        },
        fullHD: {
          max: 'max-[1920px]:rounded-r-sm',
          min: 'min-[1920px]:rounded-r-sm',
        },
        '2k': {
          max: 'max-[2560px]:rounded-r-sm',
          min: 'min-[2560px]:rounded-r-sm',
        },
        '3k': {
          max: 'max-[3072px]:rounded-r-sm',
          min: 'min-[3072px]:rounded-r-sm',
        },
        '5k': {
          max: 'max-[5120px]:rounded-r-sm',
          min: 'min-[5120px]:rounded-r-sm',
        },
        '8k': {
          max: 'max-[7680px]:rounded-r-sm',
          min: 'min-[7680px]:rounded-r-sm',
        },
      },
    },
    medium: {
      rounded: {
        default: 'rounded-md',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-md',
            min: 'min-[325px]:rounded-md',
          },
          tablet: {
            max: 'max-[768px]:rounded-md',
            min: 'min-[768px]:rounded-md',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-md',
            min: 'min-[1920px]:rounded-md',
          },
          '2k': {
            max: 'max-[2560px]:rounded-md',
            min: 'min-[2560px]:rounded-md',
          },
          '3k': {
            max: 'max-[3072px]:rounded-md',
            min: 'min-[3072px]:rounded-md',
          },
          '5k': {
            max: 'max-[5120px]:rounded-md',
            min: 'min-[5120px]:rounded-md',
          },
          '8k': {
            max: 'max-[7680px]:rounded-md',
            min: 'min-[7680px]:rounded-md',
          },
        },
      },
      top: {
        default: 'rounded-t-md',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-t-md',
            min: 'min-[325px]:rounded-t-md',
          },
          tablet: {
            max: 'max-[768px]:rounded-t-md',
            min: 'min-[768px]:rounded-t-md',
          },
        },
      },
      bottom: {
        default: 'rounded-b-md',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-b-md',
            min: 'min-[325px]:rounded-b-md',
          },
          tablet: {
            max: 'max-[768px]:rounded-b-md',
            min: 'min-[768px]:rounded-b-md',
          },
          laptop: {
            max: 'max-[1024px]:rounded-b-md',
            min: 'min-[1024px]:rounded-b-md',
          },
          desktop: {
            max: 'max-[1280px]:rounded-b-md',
            min: 'min-[1280px]:rounded-b-md',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-b-md',
            min: 'min-[1920px]:rounded-b-md',
          },
          '2k': {
            max: 'max-[2560px]:rounded-b-md',
            min: 'min-[2560px]:rounded-b-md',
          },
          '3k': {
            max: 'max-[3072px]:rounded-b-md',
            min: 'min-[3072px]:rounded-b-md',
          },
          '5k': {
            max: 'max-[5120px]:rounded-b-md',
            min: 'min-[5120px]:rounded-b-md',
          },
          '8k': {
            max: 'max-[7680px]:rounded-b-md',
            min: 'min-[7680px]:rounded-b-md',
          },
        },
      },
      left: {
        default: 'rounded-l-md',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-l-md',
            min: 'min-[325px]:rounded-l-md',
          },
          tablet: {
            max: 'max-[768px]:rounded-l-md',
            min: 'min-[768px]:rounded-l-md',
          },
          laptop: {
            max: 'max-[1024px]:rounded-l-md',
            min: 'min-[1024px]:rounded-l-md',
          },
          desktop: {
            max: 'max-[1280px]:rounded-l-md',
            min: 'min-[1280px]:rounded-l-md',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-l-md',
            min: 'min-[1920px]:rounded-l-md',
          },
          '2k': {
            max: 'max-[2560px]:rounded-l-md',
            min: 'min-[2560px]:rounded-l-md',
          },
          '3k': {
            max: 'max-[3072px]:rounded-l-md',
            min: 'min-[3072px]:rounded-l-md',
          },
          '5k': {
            max: 'max-[5120px]:rounded-l-md',
            min: 'min-[5120px]:rounded-l-md',
          },
          '8k': {
            max: 'max-[7680px]:rounded-l-md',
            min: 'min-[7680px]:rounded-l-md',
          },
        },
      },
      right: {
        default: 'rounded-r-md',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-r-md',
            min: 'min-[325px]:rounded-r-md',
          },
          tablet: {
            max: 'max-[768px]:rounded-r-md',
            min: 'min-[768px]:rounded-r-md',
          },
          laptop: {
            max: 'max-[1024px]:rounded-r-md',
            min: 'min-[1024px]:rounded-r-md',
          },
          desktop: {
            max: 'max-[1280px]:rounded-r-md',
            min: 'min-[1280px]:rounded-r-md',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-r-md',
            min: 'min-[1920px]:rounded-r-md',
          },
          '2k': {
            max: 'max-[2560px]:rounded-r-md',
            min: 'min-[2560px]:rounded-r-md',
          },
          '3k': {
            max: 'max-[3072px]:rounded-r-md',
            min: 'min-[3072px]:rounded-r-md',
          },
          '5k': {
            max: 'max-[5120px]:rounded-r-md',
            min: 'min-[5120px]:rounded-r-md',
          },
          '8k': {
            max: 'max-[7680px]:rounded-r-md',
            min: 'min-[7680px]:rounded-r-md',
          },
        },
      },
    },
    large: {
      rounded: {
        default: 'rounded-lg',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-lg',
            min: 'min-[325px]:rounded-lg',
          },
          tablet: {
            max: 'max-[768px]:rounded-lg',
            min: 'min-[768px]:rounded-lg',
          },
          laptop: {
            max: 'max-[1024px]:rounded-lg',
            min: 'min-[1024px]:rounded-lg',
          },
          desktop: {
            max: 'max-[1280px]:rounded-lg',
            min: 'min-[1280px]:rounded-lg',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-lg',
            min: 'min-[1920px]:rounded-lg',
          },
          '2k': {
            max: 'max-[2560px]:rounded-lg',
            min: 'min-[2560px]:rounded-lg',
          },
          '3k': {
            max: 'max-[3072px]:rounded-lg',
            min: 'min-[3072px]:rounded-lg',
          },
          '5k': {
            max: 'max-[5120px]:rounded-lg',
            min: 'min-[5120px]:rounded-lg',
          },
          '8k': {
            max: 'max-[7680px]:rounded-lg',
            min: 'min-[7680px]:rounded-lg',
          },
        },
      },
      top: {
        default: 'rounded-t-lg',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-t-lg',
            min: 'min-[325px]:rounded-t-lg',
          },
          tablet: {
            max: 'max-[768px]:rounded-t-lg',
            min: 'min-[768px]:rounded-t-lg',
          },
          laptop: {
            max: 'max-[1024px]:rounded-t-lg',
            min: 'min-[1024px]:rounded-t-lg',
          },
          desktop: {
            max: 'max-[1280px]:rounded-t-lg',
            min: 'min-[1280px]:rounded-t-lg',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-t-lg',
            min: 'min-[1920px]:rounded-t-lg',
          },
          '2k': {
            max: 'max-[2560px]:rounded-t-lg',
            min: 'min-[2560px]:rounded-t-lg',
          },
          '3k': {
            max: 'max-[3072px]:rounded-t-lg',
            min: 'min-[3072px]:rounded-t-lg',
          },
          '5k': {
            max: 'max-[5120px]:rounded-t-lg',
            min: 'min-[5120px]:rounded-t-lg',
          },
          '8k': {
            max: 'max-[7680px]:rounded-t-lg',
            min: 'min-[7680px]:rounded-t-lg',
          },
        },
      },
      bottom: {
        default: 'rounded-b-lg',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-b-lg',
            min: 'min-[325px]:rounded-b-lg',
          },
          tablet: {
            max: 'max-[768px]:rounded-b-lg',
            min: 'min-[768px]:rounded-b-lg',
          },
          laptop: {
            max: 'max-[1024px]:rounded-b-lg',
            min: 'min-[1024px]:rounded-b-lg',
          },
          desktop: {
            max: 'max-[1280px]:rounded-b-lg',
            min: 'min-[1280px]:rounded-b-lg',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-b-lg',
            min: 'min-[1920px]:rounded-b-lg',
          },
          '2k': {
            max: 'max-[2560px]:rounded-b-lg',
            min: 'min-[2560px]:rounded-b-lg',
          },
          '3k': {
            max: 'max-[3072px]:rounded-b-lg',
            min: 'min-[3072px]:rounded-b-lg',
          },
          '5k': {
            max: 'max-[5120px]:rounded-b-lg',
            min: 'min-[5120px]:rounded-b-lg',
          },
          '8k': {
            max: 'max-[7680px]:rounded-b-lg',
            min: 'min-[7680px]:rounded-b-lg',
          },
        },
      },
      left: {
        default: 'rounded-l-lg',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-l-lg',
            min: 'min-[325px]:rounded-l-lg',
          },
          tablet: {
            max: 'max-[768px]:rounded-l-lg',
            min: 'min-[768px]:rounded-l-lg',
          },
          laptop: {
            max: 'max-[1024px]:rounded-l-lg',
            min: 'min-[1024px]:rounded-l-lg',
          },
          desktop: {
            max: 'max-[1280px]:rounded-l-lg',
            min: 'min-[1280px]:rounded-l-lg',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-l-lg',
            min: 'min-[1920px]:rounded-l-lg',
          },
          '2k': {
            max: 'max-[2560px]:rounded-l-lg',
            min: 'min-[2560px]:rounded-l-lg',
          },
          '3k': {
            max: 'max-[3072px]:rounded-l-lg',
            min: 'min-[3072px]:rounded-l-lg',
          },
          '5k': {
            max: 'max-[5120px]:rounded-l-lg',
            min: 'min-[5120px]:rounded-l-lg',
          },
          '8k': {
            max: 'max-[7680px]:rounded-l-lg',
            min: 'min-[7680px]:rounded-l-lg',
          },
        },
      },
      right: {
        default: 'rounded-r-lg',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-r-lg',
            min: 'min-[325px]:rounded-r-lg',
          },
          tablet: {
            max: 'max-[768px]:rounded-r-lg',
            min: 'min-[768px]:rounded-r-lg',
          },
          laptop: {
            max: 'max-[1024px]:rounded-r-lg',
            min: 'min-[1024px]:rounded-r-lg',
          },
          desktop: {
            max: 'max-[1280px]:rounded-r-lg',
            min: 'min-[1280px]:rounded-r-lg',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-r-lg',
            min: 'min-[1920px]:rounded-r-lg',
          },
          '2k': {
            max: 'max-[2560px]:rounded-r-lg',
            min: 'min-[2560px]:rounded-r-lg',
          },
          '3k': {
            max: 'max-[3072px]:rounded-r-lg',
            min: 'min-[3072px]:rounded-r-lg',
          },
          '5k': {
            max: 'max-[5120px]:rounded-r-lg',
            min: 'min-[5120px]:rounded-r-lg',
          },
          '8k': {
            max: 'max-[7680px]:rounded-r-lg',
            min: 'min-[7680px]:rounded-r-lg',
          },
        },
      },
    },
    extraLarge: {
      rounded: {
        default: 'rounded-2xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-2xl',
            min: 'min-[325px]:rounded-2xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-2xl',
            min: 'min-[768px]:rounded-2xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-2xl',
            min: 'min-[1024px]:rounded-2xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-2xl',
            min: 'min-[1280px]:rounded-2xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-2xl',
            min: 'min-[1920px]:rounded-2xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-2xl',
            min: 'min-[2560px]:rounded-2xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-2xl',
            min: 'min-[3072px]:rounded-2xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-2xl',
            min: 'min-[5120px]:rounded-2xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-2xl',
            min: 'min-[7680px]:rounded-2xl',
          },
        },
      },
      top: {
        default: 'rounded-t-2xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-t-2xl',
            min: 'min-[325px]:rounded-t-2xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-t-2xl',
            min: 'min-[768px]:rounded-t-2xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-t-2xl',
            min: 'min-[1024px]:rounded-t-2xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-t-2xl',
            min: 'min-[1280px]:rounded-t-2xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-t-2xl',
            min: 'min-[1920px]:rounded-t-2xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-t-2xl',
            min: 'min-[2560px]:rounded-t-2xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-t-2xl',
            min: 'min-[3072px]:rounded-t-2xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-t-2xl',
            min: 'min-[5120px]:rounded-t-2xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-t-2xl',
            min: 'min-[7680px]:rounded-t-2xl',
          },
        },
      },
      bottom: {
        default: 'rounded-b-2xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-b-2xl',
            min: 'min-[325px]:rounded-b-2xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-b-2xl',
            min: 'min-[768px]:rounded-b-2xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-b-2xl',
            min: 'min-[1024px]:rounded-b-2xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-b-2xl',
            min: 'min-[1280px]:rounded-b-2xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-b-2xl',
            min: 'min-[1920px]:rounded-b-2xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-b-2xl',
            min: 'min-[2560px]:rounded-b-2xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-b-2xl',
            min: 'min-[3072px]:rounded-b-2xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-b-2xl',
            min: 'min-[5120px]:rounded-b-2xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-b-2xl',
            min: 'min-[7680px]:rounded-b-2xl',
          },
        },
      },
      left: {
        default: 'rounded-l-2xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-l-2xl',
            min: 'min-[325px]:rounded-l-2xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-l-2xl',
            min: 'min-[768px]:rounded-l-2xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-l-2xl',
            min: 'min-[1024px]:rounded-l-2xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-l-2xl',
            min: 'min-[1280px]:rounded-l-2xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-l-2xl',
            min: 'min-[1920px]:rounded-l-2xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-l-2xl',
            min: 'min-[2560px]:rounded-l-2xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-l-2xl',
            min: 'min-[3072px]:rounded-l-2xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-l-2xl',
            min: 'min-[5120px]:rounded-l-2xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-l-2xl',
            min: 'min-[7680px]:rounded-l-2xl',
          },
        },
      },
      right: {
        default: 'rounded-r-2xl',
        screens: {
          mobile: {
            max: 'max-[640px]:rounded-r-2xl',
            min: 'min-[325px]:rounded-r-2xl',
          },
          tablet: {
            max: 'max-[768px]:rounded-r-2xl',
            min: 'min-[768px]:rounded-r-2xl',
          },
          laptop: {
            max: 'max-[1024px]:rounded-r-2xl',
            min: 'min-[1024px]:rounded-r-2xl',
          },
          desktop: {
            max: 'max-[1280px]:rounded-r-2xl',
            min: 'min-[1280px]:rounded-r-2xl',
          },
          fullHD: {
            max: 'max-[1920px]:rounded-r-2xl',
            min: 'min-[1920px]:rounded-r-2xl',
          },
          '2k': {
            max: 'max-[2560px]:rounded-r-2xl',
            min: 'min-[2560px]:rounded-r-2xl',
          },
          '3k': {
            max: 'max-[3072px]:rounded-r-2xl',
            min: 'min-[3072px]:rounded-r-2xl',
          },
          '5k': {
            max: 'max-[5120px]:rounded-r-2xl',
            min: 'min-[5120px]:rounded-r-2xl',
          },
          '8k': {
            max: 'max-[7680px]:rounded-r-2xl',
            min: 'min-[7680px]:rounded-r-2xl',
          },
        },
      },
    },
    full: {
      rounded: 'rounded-full',
      screens: {
        mobile: {
          max: 'max-[640px]:rounded-full',
          min: 'min-[325px]:rounded-full',
        },
        tablet: {
          max: 'max-[768px]:rounded-full',
          min: 'min-[768px]:rounded-full',
        },
        laptop: {
          max: 'max-[1024px]:rounded-full',
          min: 'min-[1024px]:rounded-full',
        },
        desktop: {
          max: 'max-[1280px]:rounded-full',
          min: 'min-[1280px]:rounded-full',
        },
        fullHD: {
          max: 'max-[1920px]:rounded-full',
          min: 'min-[1920px]:rounded-full',
        },
        '2k': {
          max: 'max-[2560px]:rounded-full',
          min: 'min-[2560px]:rounded-full',
        },
        '3k': {
          max: 'max-[3072px]:rounded-full',
          min: 'min-[3072px]:rounded-full',
        },
        '5k': {
          max: 'max-[5120px]:rounded-full',
          min: 'min-[5120px]:rounded-full',
        },
        '8k': {
          max: 'max-[7680px]:rounded-full',
          min: 'min-[7680px]:rounded-full',
        },
      },
    },
    none: {
      rounded: 'rounded-none',
      screens: {
        mobile: {
          max: 'max-[640px]:rounded-none',
          min: 'min-[325px]:rounded-none',
        },
        tablet: {
          max: 'max-[768px]:rounded-none',
          min: 'min-[768px]:rounded-none',
        },
        laptop: {
          max: 'max-[1024px]:rounded-none',
          min: 'min-[1024px]:rounded-none',
        },
        desktop: {
          max: 'max-[1280px]:rounded-none',
          min: 'min-[1280px]:rounded-none',
        },
        fullHD: {
          max: 'max-[1920px]:rounded-none',
          min: 'min-[1920px]:rounded-none',
        },
        '2k': {
          max: 'max-[2560px]:rounded-none',
          min: 'min-[2560px]:rounded-none',
        },
        '3k': {
          max: 'max-[3072px]:rounded-none',
          min: 'min-[3072px]:rounded-none',
        },
        '5k': {
          max: 'max-[5120px]:rounded-none',
          min: 'min-[5120px]:rounded-none',
        },
        '8k': {
          max: 'max-[7680px]:rounded-none',
          min: 'min-[7680px]:rounded-none',
        },
      },
    },
  },
  borderRadiusOptions: {
    none: 'rounded-none',
    small: 'rounded-sm',
    base: 'rounded',
    medium: 'rounded-md',
    large: 'rounded-lg',
    extraLarge: 'rounded-xl',
    full: 'rounded-full',
  },
  textSize: {
    xSmall: 'text-xs',
    small: 'text-sm',
    medium: 'text-md',
    base: 'text-base',
    large: 'text-lg',
    xLarge: 'text-xl',
    laptop: {
      base: 'laptop:text-base',
      h3: 'laptop:text-lg',
      h2: 'laptop:text-xl',
      h1: 'laptop:text-2xl',
    },
    phone: {
      base: 'phone:text-sm',
      h3: 'phone:text-base',
      h2: 'phone:text-lg',
      h1: 'phone:text-xl',
    },
  },
  fontWeight: {
    base: 'font-normal',
    medium: 'font-medium',
    emphasis: 'font-semibold',
    heading: 'font-bold',
  },
};
